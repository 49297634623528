import React, { useState, useEffect } from 'react';

const Price = ({ changeOrder }) => {
  const [exchangeNumber, setExchangeNumber] = useState(0);

  useEffect(() => {
    const interval = 60 /* sec */ * 1000;
    var timer = setTimeout(function fetchExchangeNumber() {
      fetch(`https://blockchain.info/ticker`)
        .then((response) => response.json())
        .then((response) => {
          console.log(1 / response.USD.last);
          setExchangeNumber(1 / response.USD.last);
        });
      timer = setTimeout(fetchExchangeNumber, interval);
    });
    return () => clearInterval(timer);
  }, []);

  return (
    <ul className="price row">
      <li className="price-item col-lg-4 offset-lg-0 col-md-10 offset-md-1">
        <div className="price-card">
          <div className="price-body">
            <h3>Premium</h3>
            <p>Wallet Audit &nbsp;&bull;&nbsp; Publishable Report</p>
            <div className="price-body-value">
              <em>$ 60,000</em>
              <i>
                {exchangeNumber
                  ? ' / BTC ' + (exchangeNumber * 60000).toFixed(4)
                  : ''}
              </i>
            </div>
            <a
              onClick={changeOrder}
              className="btn accent large block"
              href="#request"
              data-value="premium"
            >
              Request Package
            </a>
          </div>
        </div>
      </li>
      <li className="price-item col-lg-4 offset-lg-0 col-md-10 offset-md-1">
        <div className="price-card">
          <div className="price-body">
            <h3>Standard</h3>
            <p>Wallet Audit</p>
            <div className="price-body-value">
              <em>$ 50,000</em>
              <i>
                {exchangeNumber
                  ? ' / BTC ' + (exchangeNumber * 50000).toFixed(4)
                  : ''}
              </i>
            </div>
            <a
              onClick={changeOrder}
              className="btn accent large block"
              href="#request"
              data-value="standard"
            >
              Request Package
            </a>
          </div>
        </div>
      </li>
      <li className="price-item col-lg-4 offset-lg-0 col-md-10 offset-md-1">
        <div className="price-card">
          <div className="price-body">
            <h3>Architecture</h3>
            <p>Architecture Audit</p>
            <div className="price-body-value">
              <em>$ 40,000</em>
              <i>
                {exchangeNumber
                  ? ' / BTC ' + (exchangeNumber * 40000).toFixed(4)
                  : ''}
              </i>
            </div>
            <a
              onClick={changeOrder}
              className="btn accent large block"
              href="#request"
              data-value="architecture"
            >
              Request Package
            </a>
          </div>
        </div>
      </li>
    </ul>
  );
};
export default Price;
