import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const RequestForm = ({ initialValues, id }) => {
  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'package-request', ...values }),
        })
          .then(() => {
            // actions.resetForm();
            actions.setStatus(true);
          })
          .finally(() => actions.setSubmitting(false));
      }}
      validate={(values) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const errors = {};
        if (!values.name) {
          errors.name = 'Name Required';
        }
        if (!values.email || !emailRegex.test(values.email)) {
          errors.email = 'Valid Email Required';
        }
        return errors;
      }}
    >
      {({ isSubmitting, status }) =>
        !status ? (
          <Form
            className="form horizontal"
            name="package-request"
            data-netlify={true}
          >
            <div className="form-group">
              <label htmlFor={`name`}>
                Your Name <span className="text-important">*</span>
              </label>
              <Field className="form-input" name="name" id="name" />
              <ErrorMessage
                name="name"
                render={(msg) => <div className="form-error">{msg}</div>}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`email`}>
                Business email <span className="text-important">*</span>{' '}
              </label>
              <Field className="form-input" name="email" id="email" />
              <ErrorMessage
                name="email"
                render={(msg) => <div className="form-error">{msg}</div>}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`package`}>
                Package <span className="text-important">*</span>
              </label>
              <div className="selectbox">
                <Field name="package" as="select" id="package">
                  <option value="premium">premium</option>
                  <option value="standard">standard</option>
                  <option value="architecture">architecture</option>
                </Field>
                <span className="selectbox-arrow">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="form-group submit">
              <button
                className="btn accent large input-height block"
                type="submit"
              >
                Request Package
              </button>
            </div>
          </Form>
        ) : (
          <p className="p-medium text-center">
            Thanks for request! We will contact you soon!
          </p>
        )
      }
    </Formik>
  );
};

export default RequestForm;
