import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const TintedImage = () => (
  <div className="tinted-image">
    <StaticImage
      src="../assets/images/start-picture.jpg"
      alt="Secure Blockchain Solutions"
    />
    <span className="tinted-image-toner"></span>
    <span className="tinted-image-gradient top"></span>
    <span className="tinted-image-gradient left"></span>
    <span className="tinted-image-gradient bottom"></span>
  </div>
);
export default TintedImage;
