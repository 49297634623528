import React, { useState } from 'react';
import Seo from '~/components/seo';
import RequestForm from '~/components/RequestForm';
import TintedImage from '~/components/TintedImage';
import Price from '~/components/Price';
import '~/assets/styles/main.scss';

const IndexPage = () => {
  const [initialForm, setInitialForm] = useState({
    name: '',
    email: '',
    package: 'premium',
  });

  function handleForm(e) {
    setInitialForm({ ...initialForm, package: e.target.dataset.value });
  }

  return (
    <>
      <Seo />
      <section className="page-section -intro" id="home">
        <div className="container">
          <div className="row">
            <div className="intro col-lg-7 col-md-9 col-12">
              <h1 className="large">
                Secure Blockchain Solutions
              </h1>
              <hr className="split" />
              <p className="p-medium">
                Blockchain security by world-renowned security researchers.
              </p>
            </div>
            <TintedImage />
          </div>
        </div>
      </section>
      <section className="page-section -secondary" id="services">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="text-center mb-36">Our Services</h2>
            </div>
          </div>
          <ul className="cards row">
            <li className="cards-item col-lg-4">
              <div className="card">
                <h3>Cryptocurrency</h3>
                <ul>
                  <li>Wallet technologies</li>
                  <li>Secure Storage</li>
                  <li>Backup strategies</li>
                  <li>Exchange Integration</li>
                </ul>
              </div>
            </li>
            <li className="cards-item col-lg-4">
              <div className="card">
                <h3>Blockchain</h3>
                <ul>
                  <li>Smart Contract Security</li>
                  <li>Multi-party signatures</li>
                  <li>Protocol security</li>
                  <li>Validator implementations</li>
                </ul>
              </div>
            </li>
            <li className="cards-item col-lg-4">
              <div className="card">
                <h3>Security</h3>
                <ul>
                  <li>Wallet audits</li>
                  <li>Risk assessments</li>
                  <li>Embedded device security</li>
                  <li>Secure architectures</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className="page-section -cta" id="request">
        <div className="container">
          <div className="row mb-36">
            <div className="col-lg-8 offset-lg-2 text-center">
              <h3 className="medium">Get a Proper Solution</h3>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <RequestForm id={0} initialValues={initialForm} />
            </div>
          </div>
        </div>
      </section>
      <section className="page-section -secondary" id="pricing">
        <div className="container">
          <div className="row mb-36">
            <div className="col-lg-8 offset-lg-2 text-center">
              <h3 className="medium">Our Audit Packages</h3>
            </div>
          </div>
          <Price changeOrder={handleForm} />
        </div>
      </section>
      <section className="page-section" id="trainings">
        <div className="container">
          <ul className="cards row">
            <li className="cards-item col-lg-6 offset-lg-0 col-sm-10 offset-sm-1">
              <a
                  className="card -invert -ast"
                  href="https://advancedsecurity.training/training/live-wallet-recovery-intro/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
              </a>
            </li>
            <li className="cards-item col-lg-6 offset-lg-0 col-sm-10 offset-sm-1">
              <div className="card -youtube">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube-nocookie.com/embed/Y1OBIGslgGM"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default IndexPage;
